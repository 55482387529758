import React, { useEffect, useState } from "react";
import './ClientsList.scss';
import { ClientsData } from "../clientsInfo";

const ClientsList = ({ setClient, currentClient, initialNumber }) => {
  const [removeAnimation, setRemoveAnimation] = useState(false);
  const handlerChooseCard = (id) => setClient(id);

  useEffect(() => {
    const closeAnimation = setTimeout(() => {
      setRemoveAnimation(true);
    }, 1500)

    return () => {
      clearTimeout(closeAnimation)
    }
  }, [])

  return (
    <div className="clients-list-wrapper">
      <div className="clients-list">
        {ClientsData.map(({ id, logoUrl, p2 }) => (
          <div key={id} className="clients-list__client">
            <div className={`clients-list__client--card ${currentClient === id ? 'active' : ''} ${removeAnimation ? 'remove-anim' : ''}`}
                 onClick={() => handlerChooseCard(id)}>
              {currentClient === id && <div className="check-mark">&#10003;</div>}
              <img src={logoUrl} alt="client" className={`${p2 ? 'p-2' : ''}`}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClientsList;
