import * as React from "react"
import '../styles/main.scss'
import NavigationBar from "../components/Navigationbar";
import ContentWrapper from "../components/ContentWrapper";

const IndexPage = () => {
  return (
    <main>
      <div className="main-container">
        <NavigationBar />
        <ContentWrapper />
      </div>
    </main>
  )
}

export default IndexPage
