import AmbraLogo from '../static/developer/ambra/nowe pogórze logo.png';
import AmbraFloorPlan1 from '../static/developer/ambra/rzuty/1ParterA.png';
import AmbraFloorPlan2 from '../static/developer/ambra/rzuty/1ParterB.png';
import AmbraFloorPlan3 from '../static/developer/ambra/rzuty/4ParterA.png';

import PortalBlueLogo from '../static/developer/portal-blue/logo_sopot_portal_blue.png';
import PortalBlueFloorPlan1 from '../static/developer/portal-blue/rzuty/A6.png';
import PortalBlueFloorPlan2 from '../static/developer/portal-blue/rzuty/A7.png';
import PortalBlueFloorPlan3 from '../static/developer/portal-blue/rzuty/B5.png';

import RepublikaMatarniaLogo from "../static/developer/republika-matarnia/republlika matarnia.jpg";
import RepublikaMatarniaFloorPlan1 from '../static/developer/republika-matarnia/rzuty/LOKAL-021121-1A.png';

import EkoParkRedaLogo from "../static/developer/eko-park-reda/ekoparkreda_logo.png";
import EkoParkRendaFloorPlan1 from '../static/developer/eko-park-reda/rzuty/A1.png';
import EkoParkRendaFloorPlan2 from '../static/developer/eko-park-reda/rzuty/Dom-w-klematisach.png';

import SpartanDomyLogo from "../static/developer/spartan-domy/spartanlogomale.png";
import SpartanDomyFloorPlan1 from '../static/developer/spartan-domy/rzuty/FoxGarden-1.png';
import SpartanDomyFloorPlan2 from '../static/developer/spartan-domy/rzuty/FoxGarden-2.png';

import EkoDeweloperLogo from "../static/developer/eko-deweloper/ZNAK EKODEWELOPER  transparentny.png";
import EkoDeweloperFloorPlan1 from '../static/developer/eko-deweloper/rzuty/5A.png';
import EkoDeweloperFloorPlan2 from '../static/developer/eko-deweloper/rzuty/5B.png';
import EkoDeweloperFloorPlan3 from '../static/developer/eko-deweloper/rzuty/10-9.png';

import DeveloperInvestGroupLogo from "../static/developer/developer-invesst-group/LOGO_OSADA.jpg";
import DeveloperInvestGroupFloorPlan1 from '../static/developer/developer-invesst-group/rzuty/A.png';
import DeveloperInvestGroupFloorPlan2 from '../static/developer/developer-invesst-group/rzuty/A3.png';
import DeveloperInvestGroupFloorPlan3 from '../static/developer/developer-invesst-group/rzuty/B.png';

import CityLogo from "../static/developer/3City/logo.png";
import CityPlan1 from "../static/developer/3City/rzuty/B.png";
import CityPlan2 from "../static/developer/3City/rzuty/B2.png";

import K2DLogo from "../static/developer/K2D/logo.png";
import K2DFloorPlan1 from "../static/developer/K2D/rzuty/A.png";
import K2DFloorPlan2 from "../static/developer/K2D/rzuty/P.png";

import ARDLogo from "../static/developer/ArdInvest/logo.png";
import ARDFloorPlan1 from "../static/developer/ArdInvest/rzuty/1.png";
import ARDFloorPlan2 from "../static/developer/ArdInvest/rzuty/1c.png";

import SIGMALOGO from "../static/developer/sigma-budownictwo/Bursztynowy-park-logo.png";
import SIGMAFloorPlan1 from "../static/developer/sigma-budownictwo/rzuty/M14.png"
import SIGMAFloorPlan2 from "../static/developer/sigma-budownictwo/rzuty/M27.png"
import SIGMAFloorPlan3 from "../static/developer/sigma-budownictwo/rzuty/M4.png"

import ARSLOGO from "../static/developer/ars-vivendi-development/ars-logo.png";
import ARSFloorPlan1 from "../static/developer/ars-vivendi-development/rzuty/M3-1.png"
import ARSFloorPlan2 from "../static/developer/ars-vivendi-development/rzuty/M5-2.png"
import ARSFloorPlan3 from "../static/developer/ars-vivendi-development/rzuty/M-0-1.png"

import FUTUROLOGO from "../static/developer/futuro-invest/futuro-logo.png"
import FUTUROFloorPlan1 from "../static/developer/futuro-invest/rzuty/8.png"
import FUTUROFloorPlan2 from "../static/developer/futuro-invest/rzuty/9.png"
import FUTUROFloorPlan3 from "../static/developer/futuro-invest/rzuty/12.png"

export const ClientsData = [
  {
    id: 1,
    title: 'Ambra Development',
    description: 'Osiedle „Nowe Pogórze” to kameralna zabudowa w otoczeniu domów jednorodzinnych.\n' +
        'Dzięki prostym i funkcjonalnym bryłom budynków całość inwestycji doskonale wpisuje się w otaczający krajobraz i harmonijnie komponuje z elewacjami okolicznych domów jednorodzinnych. \n' +
        'Pogórze jest jedną z 22 dzielnic Gdyni. W jej pobliżu znajduje się wiele terenów zielonych, a w tym rozległe lasy i rezerwaty. \n' +
        'Pogórze jest atrakcyjne nie tylko pod względem zamieszkania, ale i turystycznym. Wiele osób decyduje się tutaj inwestować w nieruchomości na wynajem ze względu na bliskość plaż, szlaków turystycznych, rezerwatów przyrody i atrakcyjnej infrastruktury. \n' +
        'Na uwagę zasługuje również fakt, że Pogórze jest objęte planami rozbudowy Północnej Kolei Aglomeracyjnej. Planowana jest budowa linii kolejowej łączącej okolice osiedla z centrum Gdyni 2021-2027 r.\n',
    logoUrl: AmbraLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Nowe Podgórze',
        number: '1ParterA',
        sqm: '56,29 ',
        floorPlanUrl: AmbraFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/nowe-podgorze/1partera.3d',
      },
      {
        streetName: 'Nowe Podgórze',
        number: '1ParterB',
        sqm: '62,21 ',
        floorPlanUrl: AmbraFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/nowe-podgorze/1parterb.3d',
      },
      {
        streetName: 'Nowe Podgórze',
        number: '4ParterA',
        sqm: '41,15',
        floorPlanUrl: AmbraFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/nowe-podgorze/4partera.3d',
      },
    ]
  },
  {
    id: 2,
    title: 'Portal Blue',
    description: 'SOPOT jest idealnym miejscem do zamieszkania, weekendowy wypoczynek, rodzinne wakacje lub lokata kapitału. Proponujemy Państwu mieszkania w kameralnym 3-piętrowym budynku, który został podzielony na dwie klatki schodowe, wyposażone w cichobieżne windy oraz halę garażową na poziomie -1 z miejscami postojowymi. Budynek oraz części wspólne zostaną wykonane z bardzo wysokiej jakości materiałów oraz z dbałością o detale, co sprawi, ze całość będzie posiadała niepowtarzalny, osobisty charakter. Zaprojektowaliśmy 25 funkcjonalnych mieszkań o powierzchni od 25 m² do 106 m² o wysokim standardzie, ciekawym układzie architektonicznym, z możliwością swobodnej aranżacji',
    logoUrl: PortalBlueLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Apartamenty Portal Blue',
        number: 'A6',
        sqm: '66,97 ',
        floorPlanUrl: PortalBlueFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/apartamenty-portal-blue-targi/a6.3d',
      },
      {
        streetName: 'Apartamenty Portal Blue',
        number: 'A7',
        sqm: '48,25 ',
        floorPlanUrl: PortalBlueFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/apartamenty-portal-blue-targi/a7-1.3d',
      },
      {
        streetName: 'Apartamenty Portal Blue',
        number: 'B5',
        sqm: '106,55 ',
        floorPlanUrl: PortalBlueFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/apartamenty-portal-blue-targi/b5.3d',
      },
    ]
  },
  {
    id: 3,
    title: 'Republika Matarnia',
    description: 'Republika Matarnia to osiedle 64 domów jednorodzinnych o powierzchni 113 m2 w zabudowie szeregowej. Do każdego z domów przynależne są działki o powierzchni od 140 do 450 m, garaż oraz miejsce na podjeździe. Teren inwestycji jest ogrodzony i objęty monitoringiem. \n' +
        'Osiedle znajduje się w granicach osiedla Klukowo należącego do gdańskiej dzielnicy Matarnia. Okolica jest cicha, o niskiej zabudowie jednorodzinnej, otoczona licznymi terenami rekreacyjnymi, umożliwia dostęp do głównych dróg komunikacyjnych Trójmiasta i podstawowej infrastruktury handlowo-usługowej i edukacyjnej.\n',
    logoUrl: RepublikaMatarniaLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Republika Matarnia',
        number: 'LOKAL-021121-1A',
        sqm: '113,66 ',
        floorPlanUrl: RepublikaMatarniaFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/republika-matarnia/lokal-021121-1a.3d',
      },
    ]
  },
  {
    id: 4,
    title: 'Eko Park Reda',
    description: 'Najwyższa jakość i przejrzyste warunki\n' +
        'Budujemy z najlepszych materiałów przez profesjonalną ekipę z wieloletnim doświadczeniem. Jako nieliczni na rynku udostępniamy dokumentację opisową i fotograficzną z każdego etapu budowy.\n' +
        ' \n' +
        'Niezależność energetyczna i ekologiczne rozwiązania\n' +
        'Dzięki zastosowaniu instalacji fotowoltaicznej o dużej mocy możliwe jest uzyskanie darmowego ogrzewania z pompy ciepła. Każdy z domów wyposażony jest we własne ujęcie wody pitnej - świeża i czysta woda z pompy głębinowej zasilanej fotowoltaiką. W budynkach zastosowano instalację pełnej rekuperacji doprowadzającą świeże powietrze do każdego z pomieszczeń. Dodatkowo system można wyposażyć w filtry wyłapujące zanieczyszczenia i wirusy.\n' +
        ' \n' +
        'Prestiżowa i urokliwa okolica\n' +
        'Osiedle jest w pełni ogrodzone co daje poczucie komfortu i bezpieczeństwa. Drogi dojazdowe doświetlają parkowe lampy LED. Budynki otulają zaprojektowane proste i naturalne ogrody. Wybierając EKO PARK REDA zyskujesz komfort życia na przedmieściu nie rezygnując ze wszystkich udogodnień, które oferuje miasto. \n',
    logoUrl: EkoParkRedaLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Eko Park Reda',
        number: 'A1',
        sqm: '188,40 ',
        floorPlanUrl: EkoParkRendaFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/eko-park-reda/a1.3d',
      },
      {
        streetName: 'Eko Park Reda',
        number: 'Dom-w-Klematisach',
        sqm: '184,60 ',
        floorPlanUrl: EkoParkRendaFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/eko-park-reda/dom-w-klematisach.3d',
      },
    ]
  },
  {
    id: 5,
    title: 'Spartan Domy',
    description: 'Korzenie firmy Spartan sięgają początku 1990 r. Z końcem 2012 r., dla lepszego zarządzania,  Spartan Development Sp. z o.o.,  przeprowadziła podział  na nowo zawiązane spółki: Spartan Jaworowe Wzgórze Sp. z o.o. oraz Spartan Domy Sp. z o.o.\n' +
        '\n' +
        'Wychodząc z założenia, że wszyscy mamy niezbywalne prawo do piękna, położyliśmy duży nacisk na zagospodarowanie terenu osiedla z uwzględnieniem otaczającej go przyrody. Istotne znaczenie miało zachowanie ładu architektonicznego osiedla tak, aby poszczególne jego etapy tworzyły harmonijną całość.\n' +
        'Na każdym etapie przygotowywania inwestycji, począwszy od projektowania, poprzez realizację, aż do sprzedaży, myślimy o ludziach, którzy staną się częścią naszego osiedla i spędzą w nim ważne chwile swojego życia. Wierzymy, że dzięki naszym staraniom sprostamy oczekiwaniom naszych przyszłych mieszkańców',
    logoUrl: SpartanDomyLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'FoxGarden Suchy Dwór k. Gdyni',
        number: 'FoxGarden-1',
        sqm: '110,85 ',
        floorPlanUrl: SpartanDomyFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/foxgarden/foxgarden-1.3d',
      },
      {
        streetName: 'FoxGarden Suchy Dwór k. Gdyni',
        number: 'FoxGarden-2',
        sqm: '110,85',
        floorPlanUrl: SpartanDomyFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/foxgarden/foxgarden-2.3d',
      },
    ]
  },
  {
    id: 6,
    title: 'Eko Deweloper',
    description: 'Osiedle Kampinova powstaje w gdańskiej dzielnicy Łostowice przy ulicy Kampinoskiej. To kompleks 5 budynków, na który będzie składać się:\n' +
        '37 mieszkań z ogródkami lub balkonami,\n' +
        '16 domów szeregowych z ogródkami i dwustanowiskowymi garażami.\n' +
        'Budynki na osiedlu zostały zaprojektowane w geometrycznych bryłach i jasnej kolorystyce. Doskonale będą harmonizować z przepięknym, zielonym otoczeniem dzielnicy Łostowice. \n' +
        '\n' +
        'Nova Lubovidzka to miejsce, w którym wygoda i nowoczesność przenikają się z naturalnym rytmem życia i bliskością przyrody. Nowoczesne osiedle, w którym zastosowana zostanie technologia zielonych dachów, powstaje przy ulicy Lubowidzkiej w dzielnicy Gdańska – Jasień.\n' +
        'Na osiedle Nova Lubovidzka składać będzie się 7 budynków, w ramach których wyodrębniono:\n' +
        '10 lokali 3-pokojowych, o pow. ok 57-62 m2,\n' +
        '17 lokali dwukondygnacyjnych o pow. użytkowej ok. 120 m2 z własnym garażem i ogrodem.\n',
    logoUrl: EkoDeweloperLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Nova Lubovidzka',
        number: '5A',
        sqm: '57,32 ',
        floorPlanUrl: EkoDeweloperFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/nova-lubovidzka/lokal-5a-nova-lubovidzka.3d',
      },
      {
        streetName: 'Nova Lubovidzka',
        number: '5B',
        sqm: '62,11 ',
        floorPlanUrl: EkoDeweloperFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/nova-lubovidzka/lokal-5b-nova-lubovidzka.3d',
      },
      {
        streetName: 'Kampinova',
        number: '10/9',
        sqm: '53,28 ',
        floorPlanUrl: EkoDeweloperFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/nova-lubovidzka/kampinova-bud-10-9a.3d',
      },
    ]
  },
  {
    id: 7,
    title: 'Developer-Invest-Group',
    description: 'Nasza aktualna propozycja to urokliwa enklawa 14 bliźniaczych domów o idealnym umiejscowieniu z dala od ruchliwych ulic.\n' +
        'Kameralne osiedle idealne dla osób ceniących sobie ciszę i spokój, nowoczesna dobrze przemyślana architektura doskonale odzwierciedla harmonijną i przytulną przestrzeń do odpoczynku.\n' +
        'Osiedle zlokalizowane nieopodal lasu, w sąsiedztwie domów jednorodzinnych usytuowane w Kosakowie na pięknym wzgórzu z widokiem na zatokę Gdańską oraz w bezpośredniej bliskości Gdyni.\n' +
        'Cicha i spokojna okolica sprzyja błogiemu lenistwu oraz odpoczynkowi od pędu życia codziennego i zgiełku miasta, a bliskość lasu, boiska wielofunkcyjnego, ścieżek pieszych i rowerowych pozwoli aktywnie spędzić czas wolny. W niedalekim sąsiedztwie znajdują się przedszkola, szkoły, sklepy oraz szereg innych usług potrzebnych w życiu codziennym.\n' +
        'Każdy z domów posiada powierzchnię 90m² i składa się z 2 poziomów. Parter to przestronny salon z wyjściem na taras i do własnego ogrodu,  kuchnia z oknem oraz toaleta. Dodatkowym atutem jest opcja własnej aranżacji parteru poprzez możliwość dodania gabinetu.\n' +
        'Pierwsze piętro stanowią trzy pokoje oraz komfortowa łazienka.\n' +
        'Powierzchnie działek kształtują się od 147 do 425 metrów².\n',
    logoUrl: DeveloperInvestGroupLogo,
    clientUrl: '',
    apartmentData: [
      {
        streetName: 'Osada Kosakowo',
        number: 'A',
        sqm: '88,47 ',
        floorPlanUrl: DeveloperInvestGroupFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/osada-kosakowo/a.3d',
      },
      {
        streetName: 'Osada Kosakowo',
        number: 'A3',
        sqm: '90 ',
        floorPlanUrl: DeveloperInvestGroupFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/osada-kosakowo/a3.3d',
      },
      {
        streetName: 'Osada Kosakowo',
        number: 'B',
        sqm: '90 ',
        floorPlanUrl: DeveloperInvestGroupFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/osada-kosakowo/b.3d',
      },
    ]
  },
  {
    id: 8,
    title: '3City Estate',
    description: `Jasieńskie Domy to nowa inwestycja domów w zabudowie bliźniaczej, znajdujących się w kameralnej dzielnicy Gdańska. Otaczająca osiedle zieleń pozwala mieszkańcom cieszyć się życiem z dala od zgiełku miasta, blisko natury i terenów zachęcających do rekreacji na świeżym powietrzu. Jednocześnie wygodne położenie Jasieńskich Domów w dynamicznie rozwijającej się części miasta to gwarancja dostępu do pełnej infrastruktury handlowo-usługowej, szkół, ośrodków zdrowia i sprawnie zorganizowanej komunikacji miejskiej. Dojazd do centrum Gdańska zajmuje jedynie 15 minut! 
    Na Jasieńskie Domy składają się dwa etapy budowy, a w nich różne układy funkcjonalne wnętrz o różnej powierzchni. Przestrzeń zaplanowana dla mniejszej i większej rodziny, zamknięta w nowoczesnej i eleganckiej bryle budynku z dużymi przeszkleniami. Na parterze znajdują się: przestronny salon z kuchnią o pow. ponad 37 m2, gabinet, hol, łazienka i garaż. Z kolei na piętrze znalazły się: cztery sypialnie, dwie łazienki (w tym jedna prywatna) oraz dwa balkony. Przemyślany projekt domu pozwoli zaaranżować wnętrza w taki sposób, by spełniły potrzeby każdego domownika – jest miejsce do wspólnego spędzania czasu w dużym salonie, do spokojnej pracy w domu, zdalnej nauki oraz prywatna sypialnia z garderobą. Dodatkowo do własnej aranżacji pozostaje poddasze o pow. ponad 70 m2 (mierzone po podłodze). Wyjątkowym udogodnieniem dla mieszkańców Jasieńskich Domów jest niezwykle duży ogród usytuowany po stronie południowej. Przestrzeń ta pozwala zorganizować na własnej działce miejsce do relaksu, grilla w rodzinnym gronie, mini plac zabaw dla dzieci, a nawet ogródek. 
    Warto wspomnieć także o tym, że domy nie będą drogie w utrzymaniu. Ekonomiczne i energooszczędne rozwiązania są w obecnych czasach bardzo istotne. Dlatego w naszych domach zamontujemy pompy ciepła. 
    
    W najbliższym czasie do naszej oferty dołączą kolejne domy jak i mieszkania o zróżnicowanych powierzchniach w III etapie osiedla.
    `,
    logoUrl: CityLogo,
    clientUrl: 'https://3cityestate.com/inwestycje/jasienskie-domy/',
    apartmentData: [
      {
        streetName: 'Jasieńskie Domy',
        number: 'B',
        sqm: '130,39 ',
        floorPlanUrl: CityPlan1,
        tourUrl: 'https://tours.3destate.pl/jasienskie-domy/karta-b.3d',
      },
      {
        streetName: 'Jasieńskie Domy',
        number: 'B2',
        sqm: '133,89 ',
        floorPlanUrl: CityPlan2,
        tourUrl: 'https://tours.3destate.pl/jasienskie-domy/karta-b2.3d',
      },
    ]
  },
  {
    id: 9,
    p2: true,
    title: 'K2 Dewelopment',
    description: `"Polna Aleja" to szesnaście nowoczesnych domów w zabudowie bliźniaczej. Zlokalizowane są w Bojanie, miejscowości położonej nieopodal Gdyni.
    Tradycyjna technologia wykonania
    z najlepszych materiałów połączona została
    z nowoczesną bryłą budynku powodując,
    że lokale są bezczynszowe i niedrogie
    w utrzymaniu.
    Funkcjonalne lokale z prywatnymi ogrodami gwarantują komfort, zacisze oraz prywatność.`,
    logoUrl: K2DLogo,
    clientUrl: 'https://k2deweloper.pl/',
    apartmentData: [
      {
        streetName: 'Polna Aleja',
        number: 'A',
        sqm: '127,7 ',
        floorPlanUrl: K2DFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/polna-aleja/a.3d',
      },
      {
        streetName: 'Polna Aleja',
        number: 'P',
        sqm: '127,7 ',
        floorPlanUrl: K2DFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/polna-aleja/p.3d',
      },
    ]
  },
  {
    id: 10,
    p2: true,
    title: 'ARD Investment',
    description: `Miło nam zaprezentować nasze nowe przedsięwzięcie deweloperskie – Osiedle Cynamonova, składające się z dziesięciu budynków mieszkalnych jednorodzinnych o dwóch lokalach mieszkalnych każdy. W ramach inwestycji powstanie łącznie dwadzieścia lokali, z czego dwanaście lokali będzie mieć powierzchnię 121,28 m2 każdy, a osiem kolejnych lokali będzie mieć powierzchnię 81,97 m2 każdy.
    Osiedle Cynamonova powstaje w Kosakowie, przy ulicy Cynamonowej, w powiecie puckim, województwie pomorskim. Planowane zakończenie inwestycji w grudniu 2022 roku.`,
    logoUrl: ARDLogo,
    clientUrl: 'http://ardinvest.pl/osiedle-cynamonowe-kosakowo',
    apartmentData: [
      {
        streetName: 'Osiedle Cynamonova Kosakowo',
        number: 'A',
        sqm: '81,97 ',
        floorPlanUrl: ARDFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/osiedle-cynamonova/cynamonowa.3d',
      }
    ]
  },
  {
    id: 11,
    p2: true,
    title: 'ARD Investment',
    description: `Miło nam zaprezentować naszą najnowszą powstającą już inwestycję Kameralne Gowino. Kameralne Gowino to nowoczesne osiedle dwunastu budynków dwulokalowych (tzw. bliźniaki) o wysokim standardzie zlokalizowane w Gowinie niedaleko Wejherowa, przy ulicy Wejherowskiej.
    Zakończenie budowy planowane na maj 2022 roku`,
    logoUrl: ARDLogo,
    clientUrl: 'http://ardinvest.pl/osiedle-kameralne-gowino',
    apartmentData: [
      {
        streetName: 'Kameralne Gowino',
        number: 'A',
        sqm: '121,86 ',
        floorPlanUrl: ARDFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/kameralne-gowino/kameralne-gowino.3d',
      }
    ]
  },
  {
    id: 12,
    p2: true,
    title: 'SIGMA BUDOWNICTWO',
    description: 'Bursztynowy Park to zespól siedmiu nowoczesnych budynków. Wszystkie zostały zaprojektowane z myślą o wygodzie mieszkańców o czym świadczy nacisk na zachowanie jak największej powierzchni terenów zielonych. Praktycznie cała przestrzeń otaczająca osiedle to zieleń z małą architekturą nawiązującą do bursztynu będącego jedocześnie nazwą osiedla',
    logoUrl: SIGMALOGO,
    clientUrl: 'https://sigmabudownictwo.pl/',
    apartmentData: [
      {
        streetName: 'Bursztynowy Park',
        number: 'M14_B2',
        sqm: '60,80',
        floorPlanUrl: SIGMAFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/bursztynowy-park/m14_b2.3d',
      },
      {
        streetName: 'Bursztynowy Park',
        number: 'M27_B2',
        sqm: '88,16',
        floorPlanUrl: SIGMAFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/bursztynowy-park/m27_b2.3d',
      },
      {
        streetName: 'Bursztynowy Park',
        number: 'M4_B2',
        sqm: '46,67',
        floorPlanUrl: SIGMAFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/bursztynowy-park/m4_b2.3d',
      }
    ]
  },
  {
    id: 13,
    p2: true,
    title: 'ARS VIVENDI DEVELOPMENT',
    description: 'Ars Vivendi Development należy do grupy Ars Vivendi – zajmującej się działalnością inwestycyjną, deweloperską oraz obrotem gruntami. Ars Vivendi Development wcześniej działała pod marką „Apartrade Grupa Deweloperska” - obecnie, tak jak pozostałe gałęzie działalności należy do wspólnej grupy.\n' +
        'Firma zajmuje się budową oraz sprzedażą nieruchomości deweloperskich. Aktualnie realizuje projekt domów jednorodzinnych, dwulokalowych w pobliżu Gdańska. W przeciągu najbliższych tygodni zostanie wprowadzona apartamentowa inwestycja w Ustce pod nazwą Ultra Marina.\n' +
        'W przygotowaniu są także kolejne projekty w Trójmieście i okolicach, które na pewno spełnią oczekiwania najbardziej wymagających Klientów.',
    logoUrl: ARSLOGO,
    clientUrl: 'https://apartrade.pl/',
    apartmentData: [
      {
        streetName: 'Nasza Sfera',
        number: '3.1',
        sqm: '128,27 ',
        floorPlanUrl: ARSFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/ars-vivendi-development/3.1.3d',
      },
      {
        streetName: 'Nasza Sfera',
        number: '5.2',
        sqm: '128,27 ',
        floorPlanUrl: ARSFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/ars-vivendi-development/5.2.3d',
      },
      {
        streetName: 'Ultra Marina',
        number: '0.1',
        sqm: '44,17 ',
        floorPlanUrl: ARSFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/ars-vivendi-development/0.1.3d',
      },
    ]
  },
  {
    id: 14,
    p2: true,
    title: 'FUTURO INVEST',
    description: 'Klimatyczna Gdańsk to ekskluzywne apartamenty w samym sercu Wyspy Sobieszewskiej. Doskonale wpisane w krajobraz, staną się wyjątkowym miejscem komfortowego wypoczynku przez cały rok dla każdego.',
    logoUrl: FUTUROLOGO,
    clientUrl: 'https://futuroinvest.pl/',
    apartmentData: [
      {
        streetName: 'Klimatyczna',
        number: '8',
        sqm: '',
        floorPlanUrl: FUTUROFloorPlan1,
        tourUrl: 'https://tours.3destate.pl/klimatyczna-gdansk/8.3d',
      },
      {
        streetName: 'Klimatyczna',
        number: '9',
        sqm: '',
        floorPlanUrl: FUTUROFloorPlan2,
        tourUrl: 'https://tours.3destate.pl/klimatyczna-gdansk/9.3d',
      },
      {
        streetName: 'Klimatyczna',
        number: '12',
        sqm: '',
        floorPlanUrl: FUTUROFloorPlan3,
        tourUrl: 'https://tours.3destate.pl/klimatyczna-gdansk/12.3d',
      }
    ]
  }
]
