import React, { useState } from "react";
import ClientsList from "./ClientsList";
import AsideClient from "./AsideClient";

const contentWrapperStyles = {
  display: 'flex',
}

const ContentWrapper = () => {
  const [currentClientView, setCurrentClientView] = useState(1);
  const [toggleIframe, setToggleIframe] = useState(false);
  const [iframeSource, setIframeSource] = useState('');

  const handlerSetCurrentClientView = (client) => setCurrentClientView(client);
  const handlerToggleIframe = (src) => {
    setIframeSource(src);
    setToggleIframe(!toggleIframe);
  }
  const closeIframe = () => {
    setIframeSource('');
    setToggleIframe(false);
  }

  return (
    <div className="content-wrapper" style={contentWrapperStyles}>
      {toggleIframe && (
        <>
          <div className="iframe-backdrop">
            <button type="button" className="iframe-backdrop__close" onClick={closeIframe}/>
            <div className="iframe-window">
              <iframe src={iframeSource} allowFullScreen={true} frameBorder="0"/>
              <button type="button" className="iframe-window__close" onClick={closeIframe}>X</button>
            </div>
          </div>
        </>
      )}
      <ClientsList setClient={(client) => handlerSetCurrentClientView(client)} currentClient={currentClientView} />
      <AsideClient currentClient={currentClientView} openViewer={(src) => handlerToggleIframe(src)} />
    </div>
  )
}

export default ContentWrapper;
