import React, { useEffect, useState } from "react";
import './AisdeClient.scss';
import { ClientsData } from "../clientsInfo";

const AsideClient = ({ currentClient, openViewer }) => {
  const [displayData, setDisplayData] = useState(ClientsData[currentClient - 1])
  const toggleTourWithIframe = (url) => openViewer(url);

  useEffect(() => {
    setDisplayData(ClientsData[currentClient - 1]);
  }, [currentClient])

  return (
    <aside>
      {displayData && (
        <>
          {displayData.clientUrl && (
            <a className="open-developer-page" href={displayData.clientUrl} target="_blank" rel="noreferrer">
              Zobacz stronę dewelopera
            </a>
          )}
          <div className="title">
            {displayData.clientUrl ? (
              <a href={displayData.clientUrl} target="_blank" rel="noreferrer">
                <img src={displayData.logoUrl} alt="client"/>
              </a>
            ) : (
              <img src={displayData.logoUrl} alt="client"/>
            )}
          </div>
          <div className="description">
            <p>{displayData.description}</p>
          </div>
          <div className="cards">
            {displayData.apartmentData.map(({ streetName, number, sqm, floorPlanUrl, tourUrl }, index) => (
              <div key={`${index}`} className="cards__card" onClick={() => toggleTourWithIframe(tourUrl)}>
                <div className="text-container h3">
                  <h3>{streetName}</h3>
                </div>
                <div className="img-container">
                  <img src={floorPlanUrl} alt="floorplan"/>
                </div>
                <div className="text-container desc">
                  <p><b>{number}</b></p>
                  <p>{sqm}<small>m<sup>2</sup></small></p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </aside>
  )
}

export default AsideClient;
