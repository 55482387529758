import React from "react";

import LOGO3DE from '../../static/navigation/logo-3de.png';
import POMERANIALOGO from '../../static/navigation/pomerania-expo-horizontal.png';

const NavStyle = {
  display: 'flex',
  height: '80px',
  padding: '0 2em',
  position: 'fixed',
  width: '100%',
  top: '0',
  left: '0',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#fff',
  zIndex: ' 10',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
}

const ImageStyle = {
  maxWidth: '200px',
  maxHeight: '60px',
}

const H3 = {
  textAlign: 'center',
  fontWeight: '600',
}

const NavigationBar = () => {
  return (
    <nav style={NavStyle}>
      <img style={ImageStyle} src={LOGO3DE} alt="3destate"/>
      <h2 className="nav-title" style={H3}>Poznaj Trójmiejskich deweloperów i ich mieszkania w 3D</h2>
      <img style={ImageStyle} src={POMERANIALOGO} alt="sbdim"/>
    </nav>
  )
}

export default NavigationBar;
